/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import "./_style.css";
import imageEle from "../../images/key_highlight.avif"

const KeyHighlight = () => {
  return (
    <HeroWrapper>
      <HeroImage
        src={imageEle}
        alt="Hero Image"
      />
      <HeroContent>
        <HeroTitleBase>
          Sodhark Mentorship : Your pathway to Software Engineer
        </HeroTitleBase>
        <HeroTitle>
          Key Program <Heighlight>Highlights</Heighlight>
        </HeroTitle>
        <HeroDescription>
          <Box>
             <Grid>
              <Heighlight>40%</Heighlight>
             </Grid>
             <Grid>
              Scholership Available*
             </Grid>
          </Box>
          <Box>
             <Grid>
              <Heighlight>36+</Heighlight>
             </Grid>
             <Grid>
              Years of Cumulative Software Engineering Experience
             </Grid>
          </Box>
          <Box>
             <Grid>
              <Heighlight>100%</Heighlight>
             </Grid>
             <Grid>
              Real world product development exposure.
             </Grid>
          </Box>
        </HeroDescription>
        <UL>
          <LI>
            <span class="material-symbols-outlined default_color">check_circle</span>
            Get valuable job in poineered IT Organizations.
          </LI>
          <LI>
            <span class="material-symbols-outlined default_color">check_circle</span>
            15+ Years Eperienced Faculty
          </LI>
          <LI>
            <span class="material-symbols-outlined default_color">check_circle</span>
            Start with Core Java to advanced FullStack product development
          </LI>
          <LI>
            <span class="material-symbols-outlined default_color">check_circle</span>
            Enablement of AI tools and technologies in software engineering.
          </LI>
          <LI>
            <span class="material-symbols-outlined default_color">check_circle</span>
            Constructive Feedback and Advance by best practices.
          </LI>
          <LI>
            <span class="material-symbols-outlined default_color">check_circle</span>
            2 Months real product development exposure with certification
          </LI>
          <LI>
            <span class="material-symbols-outlined default_color">check_circle</span>
            Idea to product development system design.
          </LI>
        </UL>
      </HeroContent>
    </HeroWrapper>
  );
};

const Box = styled.div`
  width: 33%;
    text-align: center;
`;
const Grid = styled.div`
  margin-top: 10px;
`;

const Heighlight = styled.span`
  background: linear-gradient(230deg, #ef4236 -5.19%, #faa93f 109.43%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const I = styled.i`
  font-style: italic;
  margin-top: .25rem !important;
  color: #0056d2;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
`;

const LI = styled.li`
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
    display: flex !important;
    margin-bottom: .5rem !important;
    gap: .5rem !important;

    @media (max-width: 420px) {
      font-size: 12px;
      line-height: 15px;
    }

    @media (max-width: 1199.98px) {
      font-size: 14px;
    }
`;

const UL = styled.ul`
  padding-left: 0;
  list-style: none;
  margin-top: 50px !important;
  font-size: 14px;
`;

const HeroWrapper = styled.section`
  display: flex;
  margin-top: 35px;
  direction: rtl;
  background-color: #f6f7f9;
  padding-right: 50px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
    padding-right: 0;
  }
`;

const HeroContent = styled.div`
  border-radius: 6px;
  direction: ltr;
  background-color: #f6f7f9;
  display: flex;
  width: 56%;
  flex-direction: column;
  align-items: start;
  font-family: Inter, sans-serif;
  margin: 0 auto;
  padding: 37px 75px;
  @media (max-width: 991px) {
    width: 100%;
    max-width: 100%;
    margin-top: 19px;
    padding: 0 20px;
  }
`;

const HeroTitle = styled.h1`
  color: #072c50;
  text-align: start;
  align-self: start;
  margin-left: 10px;
  font: 500 30px Encode Sans Semi Condensed, -apple-system, Roboto, Helvetica,sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
`;

const HeroTitleBase = styled.h1`
  color: #072c50;
  text-align: start;
  align-self: start;
  margin-left: 10px;
  font: 1 18px Encode Sans Semi Condensed, -apple-system, Roboto, Helvetica, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
`;

const HeroDescription = styled.div`
  color: #344054;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  text-align: start;
  align-self: start;
  margin-top: 21px;
  display: flex;
  padding: 10px;
  border: 1px solid #f7965d30;
  border-radius: 5px;
  box-shadow: 10px 5px 5px #f7965d30;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 46px;
  width: 410px;
  max-width: 100%;
  gap: 19px;
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Button = styled.button`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 10px 18px;
  border: none;
  cursor: pointer;
`;

const PrimaryButton = styled(Button)`
  background: linear-gradient(230deg, #ef4236 -5.19%, #faa93f 109.43%);
  color: #fff;
`;

const SecondaryButton = styled(Button)`
  background-color: #fff;
  color: #072c50;
  border: 1px solid #d0d5dd;
`;

const TrustPilot = styled.img`
  aspect-ratio: 5.85;
  object-fit: contain;
  object-position: center;
  width: 158px;
  margin-top: 45px;
  max-width: 100%;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const RatingWrapper = styled.div`
  display: flex;
  margin-top: 14px;
  width: 198px;
  max-width: 100%;
  gap: 15px;
`;

const RatingStars = styled.img`
  aspect-ratio: 2.53;
  object-fit: contain;
  object-position: center;
  width: 86px;
`;

const RatingInfo = styled.div`
  display: flex;
  gap: 4px;
  flex: 1;
  margin: auto 0;
`;

const RatingScore = styled.div`
  color: #7f56d9;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
`;

const ReviewCount = styled.div`
  color: #344054;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  margin: auto 0;
`;

const HeroImage = styled.img`
  aspect-ratio: 0.86;
  object-fit: contain;
  object-position: center;
  width: 40%;
  border-radius: 6px;
  @media (max-width: 991px) {
    width: 100%;
    max-width: 100%;
    margin-top: 19px;
  }
`;

export default KeyHighlight;
