/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import Header from "./Header";
import HeroSection from "./HeroSection";
import LearnAndGrow from "./LearnAndGrow";
import CoursesOffered from "./CoursesOffered";
import ProgramsOffered from "./ProgramsOffered/ProgramsOffered";
import ContactForm from "./ContactForm";
import Footer from "../CommonComponents/Footer/Footer";
import Value from "./Value";
import KeyHighlight from "./KeyHighlight";
import WhyToJoin from "./WhyToJoin";

const Mentorship = () => {
  return (
    <HomePageWrapper>
      <Header />
      <HeroSection />
      <Value />
      <KeyHighlight />
      <WhyToJoin />
      <LearnAndGrow />
      <CoursesOffered />
      <ContactForm />
      <Footer />
    </HomePageWrapper>
  );
};

const HomePageWrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 35px 0 35px;
  @media (max-width: 991px) {
    padding-bottom: 100px;
  }
`;

export default Mentorship;
