/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import "./_style.css";
import imageEle from "../../images/information_tech.png"

const HeroSection = () => {
  return (
    <HeroWrapper>
      <HeroImage
        src={imageEle}
        alt="Hero Image"
      />
      <HeroContent>
        <HeroTitle>
          Full Stack Product Development Mentorship
        </HeroTitle>
        <HeroDescription>
          A proven mentorship programme, which will advance you from core java to a full stack developer, and define your career path with getting real worl product development experience and mentorship. This programme will boost your
          software engineering confidence with advancing your self with data structure & algorithms, system design and AI and generative AI tools and technologies.
        </HeroDescription>
        <UL>
          <LI>
            <span class="material-symbols-outlined default_color">check_circle</span>
            Full stack development mentorship
          </LI>
          <LI>
            <span class="material-symbols-outlined default_color">check_circle</span>
            Define path for your success
          </LI>
          <LI>
            <span class="material-symbols-outlined default_color">check_circle</span>
            Mock interview and preperation
          </LI>
          <LI>
            <span class="material-symbols-outlined default_color">check_circle</span>
            Profile building
          </LI>
          <LI>
            <span class="material-symbols-outlined default_color">check_circle</span>
            Placement to best IT organization
          </LI>
          <LI>
            <span class="material-symbols-outlined default_color">check_circle</span>
            Internship project certification with industrial exposure
          </LI>
          <LI>
            <span class="material-symbols-outlined default_color">check_circle</span>
            Certification for real product development and experience
          </LI>
        </UL>
        <ButtonGroup>
          <PrimaryButton onClick={() => { window.location.href = "/contactus"; }}>Apply</PrimaryButton>
        </ButtonGroup>
      </HeroContent>
    </HeroWrapper>
  );
};

const I = styled.i`
  font-style: italic;
  margin-top: .25rem !important;
  color: #0056d2;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
`;

const LI = styled.li`
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
    display: flex !important;
    margin-bottom: .5rem !important;
    gap: .5rem !important;

    @media (max-width: 420px) {
      font-size: 12px;
      line-height: 15px;
    }

    @media (max-width: 1199.98px) {
      font-size: 14px;
    }
`;

const UL = styled.ul`
  padding-left: 0;
  list-style: none;
  margin-bottom: 1.5rem !important;
  font-size: 14px;
`;

const HeroWrapper = styled.section`
  display: flex;
  margin-top: 35px;
  direction: rtl;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const HeroContent = styled.div`
  border-radius: 6px;
  direction: ltr;
  background-color: #f6f7f9;
  display: flex;
  width: 56%;
  flex-direction: column;
  align-items: start;
  font-family: Inter, sans-serif;
  margin: 0 auto;
  padding: 37px 75px;
  @media (max-width: 991px) {
    width: 100%;
    max-width: 100%;
    margin-top: 19px;
    padding: 0 20px;
  }
`;

const HeroTitle = styled.h1`
  color: #072c50;
  text-align: start;
  align-self: start;
  margin-left: 10px;
  font: 500 54px Encode Sans Semi Condensed, -apple-system, Roboto, Helvetica,sans-serif;
  background: linear-gradient(230deg, #ef4236 -5.19%, #faa93f 109.43%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
`;

const HeroDescription = styled.p`
  color: #344054;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  text-align: start;
  align-self: start;
  margin-top: 21px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 46px;
  width: 410px;
  max-width: 100%;
  gap: 19px;
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Button = styled.button`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 10px 18px;
  border: none;
  cursor: pointer;
`;

const PrimaryButton = styled(Button)`
  background: linear-gradient(230deg, #ef4236 -5.19%, #faa93f 109.43%);
  color: #fff;
`;

const SecondaryButton = styled(Button)`
  background-color: #fff;
  color: #072c50;
  border: 1px solid #d0d5dd;
`;

const TrustPilot = styled.img`
  aspect-ratio: 5.85;
  object-fit: contain;
  object-position: center;
  width: 158px;
  margin-top: 45px;
  max-width: 100%;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const RatingWrapper = styled.div`
  display: flex;
  margin-top: 14px;
  width: 198px;
  max-width: 100%;
  gap: 15px;
`;

const RatingStars = styled.img`
  aspect-ratio: 2.53;
  object-fit: contain;
  object-position: center;
  width: 86px;
`;

const RatingInfo = styled.div`
  display: flex;
  gap: 4px;
  flex: 1;
  margin: auto 0;
`;

const RatingScore = styled.div`
  color: #7f56d9;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
`;

const ReviewCount = styled.div`
  color: #344054;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  margin: auto 0;
`;

const HeroImage = styled.img`
  aspect-ratio: 0.86;
  object-position: center;
  width: 44%;
  border-radius: 6px;
  @media (max-width: 991px) {
    width: 100%;
    max-width: 100%;
    margin-top: 19px;
  }
`;

export default HeroSection;
