/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import { BookOpen, PencilRuler, Briefcase,HelpCircle,TrendingUp,UserCheck } from 'lucide-react';
import "./_style.css";

const Value = () => {
  const features = [
    {
      title: "Structured Learning Path",
      image:
      <BookOpen className="default_color_icon"/>,
      description:
        "Set a strong foundation with mentorship from industry experties in full stack product development.",
    },
    {
      title: "Practical, Project-Based Approach",
      image:
      <PencilRuler className="default_color_icon"/>,
      description:
        "Develop software engineering skills by practicing during real world product development.",
    },
    {
      title: "Industry-Relevant Skill Development",
      image:
      <Briefcase className="default_color_icon"/>,
      description:
        "Build Profile and get placed in IT industry, with our exclusive job assistant programme.",
    },
  ];
  const features1 = [
    {
      title: "Guidance from Experts",
      image:
      <HelpCircle className="default_color_icon"/>,
      description:
        "Set a strong foundation with mentorship from industry experties in full stack product development.",
    },
    {
      title: "Job Assistance & Career Growth",
      image:
      <TrendingUp className="default_color_icon"/>,
      description:
        "Develop software engineering skills by practicing during real world product development.",
    },
    {
      title: "Holistic Development",
      image:
      <UserCheck className="default_color_icon"/>,
      description:
        "Build Profile and get placed in IT industry, with our exclusive job assistant programme.",
    },
  ];

  return (
    <LearnAndGrowSection>
      <SectionTitle>Advance your career in <Heighlight>Product Development with Offline Mentorship</Heighlight></SectionTitle>
      <FeatureContainer>
        {features.map((feature, index) => (
          <FeatureCard key={index}>
            {feature.image}
            <FeatureContent>
              <FeatureTitle>{feature.title}</FeatureTitle>
              {/* <FeatureDescription>{feature.description}</FeatureDescription> */}
            </FeatureContent>
          </FeatureCard>
        ))}
      </FeatureContainer>
      <FeatureContainer>
        {features1.map((feature, index) => (
          <FeatureCard key={index}>
            {feature.image}
            <FeatureContent>
              <FeatureTitle>{feature.title}</FeatureTitle>
              {/* <FeatureDescription>{feature.description}</FeatureDescription> */}
            </FeatureContent>
          </FeatureCard>
        ))}
      </FeatureContainer>
      {/* <ExploreButton onClick={()=>{window.location.href="/programmes"}}>Explore Plans</ExploreButton> */}
    </LearnAndGrowSection>
  );
};

const Heighlight = styled.span`
  background: linear-gradient(230deg, #ef4236 -5.19%, #faa93f 109.43%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const LearnAndGrowSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const SectionTitle = styled.h2`
  color: #000000;
  text-align: center;
  position: relative;
  right: 60px;
  font: 500 34px Encode Sans Semi Condensed, -apple-system, Roboto, Helvetica,
    sans-serif;
  @media (max-width: 991px) {
    margin: 40px 0 0 10px;
  }
`;

const FeatureContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1110px;
  margin-top: 53px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 33px;
    max-width: 100%;
    margin-top: 40px;
  }
`;

const FeatureCard = styled.div`
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  width: 32%;
  padding-bottom: 42px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  font-size: 18px;
  font-weight: 500;
  align-items: center;
  text-align: center;
  padding-top: 40px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const FeatureImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 126px;
  z-index: 10;
  margin-top: -38px;
  max-width: 100%;
`;

const FeatureContent = styled.div`
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  align-items: start;
  padding: 0 53px 0 25px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const FeatureTitle = styled.h3`
  font: 500 18px/1 Encode Sans Condensed,-apple-system,Roboto,Helvetica,sans-serif;
`;

const FeatureDescription = styled.p`
  margin-top: 12px;
  font: 400 16px/21px Inter, sans-serif;
  text-align: justify;
`;

const ExploreButton = styled.button`
  border-radius: 8px;
  border: 1px solid #7f56d9;
  background: #fff;
  color: #7f56d9;
  font: 600 16px Inter, sans-serif;
  padding: 10px 18px;
  margin-top: 38px;
  cursor: pointer;
`;

export default Value;
