/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const LearnAndGrow = () => {
  const features = [
    {
      title: "Experience",
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/e9ffa3ca8694e4a4cdbbb6011c786f8ef583940410a449c62b1f0e9945d49a80?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
      description:
        "Set a strong foundation with mentorship from industry experties in full stack product development.",
    },
    {
      title: "Practice",
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/a353dfa6605de47b41117e34ac10f0925018a6ec43a316b4092c98230745048e?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
      description:
        "Develop software engineering skills by practicing during real world product development.",
    },
    {
      title: "Apply",
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/4aa1a1095c2d5483f14392208ee6add041532e26a665295e2eabdc55ef8b17f5?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
      description:
        "Build Profile and get placed in IT industry, with our exclusive job assistant programme.",
    },
  ];

  return (
    <LearnAndGrowSection>
      <SectionTitle>Learn and Grow</SectionTitle>
      <FeatureContainer>
        {features.map((feature, index) => (
          <FeatureCard key={index}>
            <FeatureImage src={feature.image} alt={feature.title} />
            <FeatureContent>
              <FeatureTitle>{feature.title}</FeatureTitle>
              <FeatureDescription>{feature.description}</FeatureDescription>
            </FeatureContent>
          </FeatureCard>
        ))}
      </FeatureContainer>
      {/* <ExploreButton onClick={()=>{window.location.href="/programmes"}}>Explore Plans</ExploreButton> */}
    </LearnAndGrowSection>
  );
};

const LearnAndGrowSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 146px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const SectionTitle = styled.h2`
  color: #000000;
  text-align: center;
  font: 500 34px Encode Sans Semi Condensed, -apple-system, Roboto, Helvetica,
    sans-serif;
  @media (max-width: 991px) {
    margin: 40px 0 0 10px;
  }
`;

const FeatureContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1110px;
  margin-top: 53px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 33px;
    max-width: 100%;
    margin-top: 40px;
  }
`;

const FeatureCard = styled.div`
  border-radius: 13px;
  // background-color: #072c50;
  display: flex;
  flex-direction: column;
  // color: #ffffff;
  width: 32%;
  padding-bottom: 42px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  font-size:18px;
  font-weight:500;
  --bs-bg-opacity: 1;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const FeatureImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 126px;
  z-index: 10;
  margin-top: -38px;
  max-width: 100%;
`;

const FeatureContent = styled.div`
  display: flex;
  margin-top: 7px;
  flex-direction: column;
  align-items: start;
  padding: 0 53px 0 25px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const FeatureTitle = styled.h3`
  font: 600 26px/1 Encode Sans Condensed, -apple-system, Roboto, Helvetica,
    sans-serif;
`;

const FeatureDescription = styled.p`
  margin-top: 12px;
  font: 400 16px/21px Inter, sans-serif;
  text-align: justify;
`;

const ExploreButton = styled.button`
  border-radius: 8px;
  border: 1px solid #7f56d9;
  background: #fff;
  color: #7f56d9;
  font: 600 16px Inter, sans-serif;
  padding: 10px 18px;
  margin-top: 38px;
  cursor: pointer;
`;

export default LearnAndGrow;
