/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import styled from "styled-components";
import {init,saveContact} from '../../utility';

const FormWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const FormContent = styled.div`
  display: flex;
  width: 480px;
  max-width: 91%;
  margin-left:10px;
  flex-direction: column;
  justify-content: start;
`;

const FormHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  color: #101828;
  letter-spacing: -0.72px;
  justify-content: start;
  font: 600 36px/1 "Encode Sans Semi Expanded", sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const HeaderIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 44px;
  align-self: stretch;
  margin: auto 0;
`;

const HeaderTitle = styled.h1`
  align-self: stretch;
  width: 448px;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const HeaderSubtitle = styled.p`
  color: #475467;
  margin-top: 18px;
  font: 400 20px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const StyledForm = styled.form`
  display: flex;
  margin-top: 43px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const FormFields = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  row-gap: 25px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const NameFields = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  gap: 32px;
  font-family: Inter, sans-serif;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  flex-basis: 0%;
`;

const InputLabel = styled.label`
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
`;

const Input = styled.input`
  align-items: center;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  margin-top: 6px;
  width: 100%;
  gap: 8px;
  overflow: hidden;
  font-size: 16px;
  color: #667085;
  font-weight: 400;
  justify-content: start;
  padding: 12px 16px;
`;

const PhoneInput = styled.div`
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  margin-top: 6px;
  width: 100%;
  overflow: hidden;
  font-size: 16px;
  font-weight: 400;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const PhoneDropdown = styled.select`
  display: flex;
  align-items: center;
  overflow: hidden;
  color: #101828;
  white-space: nowrap;
  justify-content: space-between;
  height: 100%;
  padding: 12px 12px 12px 16px;
  border: none;
  background: transparent;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const PhoneNumber = styled.input`
  align-self: start;
  flex: 1;
  min-width: 240px;
  gap: 8px;
  color: #667085;
  flex-basis: 12px;
  padding: 12px 16px 12px 0;
  border: none;
  background: transparent;
`;

const TextArea = styled.textarea`
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  min-height: 128px;
  margin-top: 6px;
  width: 100%;
  gap: 8px;
  flex: 1;
  padding: 10px 16px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
  align-items: center;
  gap: 12px;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
`;

const CheckboxLabel = styled.label`
  color: #475467;
  text-decoration-line: underline;
  align-self: stretch;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
  font: 400 16px/24px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SubmitButton = styled.button`
  border-radius: 8px;
  border: 1px solid #ef4236;
  background: linear-gradient(77deg, #ef4236 25.76%, #faa93f 85.58%);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font: 600 16px Inter, sans-serif;
  width: 100%;
  padding: 12px 20px;
  margin-top: 32px;
  cursor: pointer;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

function EventRegistration() {

  const [contact,setContact] = useState({first_name:'',last_name:'',email:'',mobile_number:'',message:''});
  init();

  return (
    <FormWrapper>
      <FormContent>
        <FormHeader>
          <HeaderContent>
            <HeaderIcon
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/9aca342f04c93658865eed4505b6a123c8252f2d928fd3d4236106a4debe6d85?placeholderIfAbsent=true&apiKey=a6dad463439244ae86e3212633b4369a"
              alt=""
            />
            <HeaderTitle>Weekend Internship Drive</HeaderTitle>
          </HeaderContent>
          <HeaderSubtitle>
            Every Saturday, starting on 15 Feb 2025 for limited time. Register and send your CV to sodharktechnologies@sodhark.com
          </HeaderSubtitle>
        </FormHeader>
        <StyledForm>
          <FormFields>
            <NameFields>
              <InputField>
                <InputLabel htmlFor="firstName">First name</InputLabel>
                <Input type="text" id="firstName" placeholder="Full Name" defaultValue={contact.first_name.trim()} onKeyUp={(event)=>{
                let contactObj = Object.assign({},contact);
                contactObj.first_name = event.target.value.trim();
                setContact(contactObj);
              }} />
              </InputField>
              <InputField>
                <InputLabel htmlFor="lastName">Last name</InputLabel>
                <Input type="text" id="lastName" placeholder="Course" defaultValue={contact.last_name.trim()} onKeyUp={(event)=>{
                let contactObj = Object.assign({},contact);
                contactObj.last_name = event.target.value.trim();
                setContact(contactObj);
              }} />
              </InputField>
            </NameFields>
            <InputField>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input type="email" id="email" placeholder="you@company.com" defaultValue={contact.email.trim()} onKeyUp={(event)=>{
                let contactObj = Object.assign({},contact);
                contactObj.email = event.target.value.trim();
                setContact(contactObj);
              }} />
            </InputField>
            <InputField>
              <InputLabel htmlFor="phone">Phone number</InputLabel>
              <PhoneInput>
                <PhoneDropdown>
                  <option>IN</option>
                </PhoneDropdown>
                <PhoneNumber
                  type="tel"
                  id="phone"
                  placeholder="+91 (555) 000-0000"
                  defaultValue={contact.mobile_number.trim()} onKeyUp={(event)=>{
                    let contactObj = Object.assign({},contact);
                    contactObj.mobile_number = event.target.value.trim();
                    setContact(contactObj);
                  }}
                />
              </PhoneInput>
            </InputField>
            {/* <InputField>
              <InputLabel htmlFor="message">Message</InputLabel>
              <TextArea id="message" defaultValue={contact.message.trim()} onKeyUp={(event)=>{
                let contactObj = Object.assign({},contact);
                contactObj.message = event.target.value.trim();
                setContact(contactObj);
              }} />
            </InputField> */}
            {/* <CheckboxWrapper>
              <Checkbox type="checkbox" id="privacy" />
              <CheckboxLabel htmlFor="privacy">
                You agree to our friendly privacy policy.
              </CheckboxLabel>
            </CheckboxWrapper> */}
          </FormFields>
          <SubmitButton type="submit" onClick={(event)=>{
              event.preventDefault();
              event.stopPropagation();
              saveContact(contact).then(()=>{
                alert("Thanks, we will get back to you!");
                setContact({first_name : '',last_name : '', email : '', mobile_number : '', message : ''});
              }).catch(()=>{
                alert("failed to save");
              })
            }} >Send message</SubmitButton>
        </StyledForm>
      </FormContent>
    </FormWrapper>
  );
}

export default EventRegistration;
