import React, { useRef, useEffect } from "react";

const Term = () => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1 style={{ textAlign: 'center' }}>Terms and Conditions</h1>

      <p>
        Welcome to Sodhark Technologies ("we," "us," or "our"). By enrolling in our programs or accessing our services, you agree to abide by the following terms and conditions. Please read them carefully before proceeding.
      </p>

      <h2>1. Eligibility</h2>
      <p>
        1.1 All participants must be at least 16 years of age or have parental consent to enroll in any program. <br />
        1.2 Enrollment is open to individuals who meet the eligibility criteria specified for each program, including educational background or skill level, where applicable.
      </p>

      <h2>2. Enrollment and Fees</h2>
      <p>
        2.1 All fees must be paid in full prior to the start of any program unless a payment plan has been agreed upon. <br />
        2.2 Fees are non-refundable except in specific cases such as program cancellation by us or under special refund policies detailed in Section 3. <br />
        2.3 Any failure to complete payments on time may result in suspension or termination of enrollment.
      </p>

      <h2>3. Refund and Cancellation Policy</h2>
      <p>
        3.1 We offer a [Number of Days] day cooling-off period for cancellations, during which you may receive a full refund, minus any administrative fees. <br />
        3.2 No refunds will be issued after the cooling-off period, except in the case of medical emergencies or under exceptional circumstances, at our discretion. <br />
        3.3 If a program is canceled or rescheduled by us, participants will be given the option to receive a full refund or transfer to another available session.
      </p>

      <h2>4. Code of Conduct</h2>
      <p>
        4.1 All participants must behave respectfully toward fellow learners, instructors, and staff members. Any form of harassment, discrimination, or disruptive behavior will not be tolerated. <br />
        4.2 Participants are required to follow all guidelines set forth by instructors and adhere to deadlines for assignments, projects, or assessments. <br />
        4.3 Failure to comply with the code of conduct may result in disciplinary actions, including suspension or expulsion from the program.
      </p>

      <h2>5. Intellectual Property</h2>
      <p>
        5.1 All course materials, content, and intellectual property provided during the program are owned by Sodhark Technologies or its licensors. <br />
        5.2 Participants may not reproduce, distribute, or use the materials for commercial purposes without explicit written permission. <br />
        5.3 Unauthorized use of materials may lead to legal action.
      </p>

      <h2>6. Certificates and Assessment</h2>
      <p>
        6.1 Certificates will be issued upon successful completion of the program, subject to meeting all assessment requirements. <br />
        6.2 We reserve the right to withhold certificates if participants fail to meet attendance, participation, or assessment criteria.
      </p>

      <h2>7. Privacy Policy</h2>
      <p>
        7.1 We are committed to protecting your privacy and personal information. Any data collected during enrollment or participation will be used solely for the purpose of delivering services. <br />
        7.2 Personal information will not be shared with third parties unless required by law or to facilitate program delivery (e.g., issuing certifications). <br />
        7.3 By enrolling, you agree to our use of your data in accordance with our Privacy Policy <a href='/privacy'>Policy</a>.
      </p>

      <h2>8. Liability</h2>
      <p>
        8.1 While we strive to provide a high-quality learning experience, we do not guarantee that participants will achieve specific results or career outcomes. <br />
        8.2 We are not liable for any indirect, incidental, or consequential damages arising from the use of our services. <br />
        8.3 Participants are responsible for ensuring they have the necessary equipment and software to access online programs, if applicable.
      </p>

      <h2>9. Modifications</h2>
      <p>
        9.1 We reserve the right to amend these terms and conditions at any time. Participants will be notified of any significant changes via email or through the program portal. <br />
        9.2 Continued enrollment in the program following the modification of terms indicates acceptance of the revised conditions.
      </p>

      <h2>10. Governing Law</h2>
      <p>
        10.1 These terms and conditions shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles. <br />
        10.2 Any disputes arising under these terms shall be resolved in the courts located in [Jaipur, Rajasthan].
      </p>

      <h2>11. Contact Information</h2>
      <p>
        For any queries or concerns, please contact us at: <br />
        <strong>Sodhark Technologies</strong> <br />
        Email: info@sodhark.com <br />
        Phone: +91-141-6657675 <br />
        Address: 237, Giriraj Nagar, Muhana Mandi Road, Manasarovar, Jaipur, Raj - 302020
        <br/>
        <br/>
      </p>
    </div>
    );
};

export default Term;
