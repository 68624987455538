/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import styled from "styled-components";
import {init,saveContact} from '../../utility';

const ContactForm = () => {

  const [contact,setContact] = useState({first_name : '',last_name : '', email : '', mobile_number : '', message : ''});
  init();
  return (
    <ContactSection>
      <ContactContent>
        <ContactInfo>
          <ContactTitle>Still unsure?
          Need answers ? Call us!</ContactTitle>
          <ContactTitleBase>Get free counselling from our career expert</ContactTitleBase>
          {/* <ContactText>
            You can reach anytime via{" "}
            <ContactEmail href="mailto:sonu@sodhark.com">
              sonu@sodhark.com
            </ContactEmail>
          </ContactText> */}
          <ContactImage
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b90ae5bf077abef9a4e463fdcf3b43caa6409900d53b5aa47258b695d3a28d99?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
            alt="Contact illustration"
          />
        </ContactInfo>
        <FormContainer>
          <Form>
            <FormField>
              <Label htmlFor="name">Name</Label>
              <Input type="text" id="name" placeholder="Your name" defaultValue={contact.first_name.trim() + ' ' + contact.last_name.trim()} onKeyUp={(event)=>{
                let contactObj = Object.assign({},contact);
                contactObj.first_name = event.target.value.trim().split(" ")[0];
                contactObj.last_name = event.target.value.trim().split(" ")[1];
                setContact(contactObj);
              }}/>
            </FormField>
            <FormField>
              <Label htmlFor="email">Email</Label>
              <Input type="email" id="email" placeholder="you@company.com" defaultValue={contact.email.trim()} onKeyUp={(event)=>{
                let contactObj = Object.assign({},contact);
                contactObj.email = event.target.value.trim();
                setContact(contactObj);
              }}/>
            </FormField>
            <FormField>
              <Label htmlFor="phone">Phone number</Label>
              <PhoneInput>
                <CountryCode>
                  IN
                  <DropdownIcon
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/9f288935232c2b601ff5bd7f94908d897dfaab83742491521bc37030849a2b7c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
                    alt="Dropdown"
                  />
                </CountryCode>
                <Input type="tel" id="phone" placeholder="+91 000-0000" defaultValue={contact.mobile_number.trim()} onKeyUp={(event)=>{
                let contactObj = Object.assign({},contact);
                contactObj.mobile_number = event.target.value.trim();
                setContact(contactObj);
              }}/>
              </PhoneInput>
            </FormField>
            <FormField>
              <Label htmlFor="message">How can we help?</Label>
              <Textarea id="message" placeholder="Tell about requirement" defaultValue={contact.message.trim()} onKeyUp={(event)=>{
                let contactObj = Object.assign({},contact);
                contactObj.message = event.target.value.trim();
                setContact(contactObj);
              }}/>
            </FormField>
            <SubmitButton type="submit" onClick={(event)=>{
              event.preventDefault();
              event.stopPropagation();
              saveContact(contact).then(()=>{
                alert("saved successfully");
                setContact({first_name : '',last_name : '', email : '', mobile_number : '', message : ''});
              }).catch(()=>{
                alert("failed to save");
              })
            }}>Get started</SubmitButton>
          </Form>
        </FormContainer>
      </ContactContent>
    </ContactSection>
  );
};

const ContactTitleBase = styled.p`
  text-align: center;
  font: 24px Encode Sans Semi Condensed, -apple-system, Roboto, Helvetica,
    sans-serif;
  color:black;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ContactSection = styled.section`
  border-radius: 7px;
  display: flex;
  width: 100%;
  padding: 66px 70px;
  width: calc(100vw - 40px);
  position: relative;
  left: 20px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const ContactContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 1125px;
  @media (max-width: 991px) {
    flex-direction: column;
    max-width: 100%;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: #ffffff;
  font-weight: 500;
  width: 50%;
  @media (max-width: 991px) {
    width: 100%;
    max-width: 100%;
    margin-top: 40px;
  }
`;

const ContactTitle = styled.h2`
  text-align: center;
  font: 34px Encode Sans Semi Condensed, -apple-system, Roboto, Helvetica,
    sans-serif;
  color:black;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ContactText = styled.p`
  align-self: start;
  margin-top: 27px;
  font: 18px Inter, sans-serif;
  color:black;
`;

const ContactEmail = styled.a`
  font-weight: 700;
  text-decoration: underline;
  color: inherit;
`;

const ContactImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  width: 302px;
  align-self: center;
  margin-top: 95px;
  max-width: 100%;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const FormContainer = styled.div`
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0px 8px 24px rgba(52, 64, 84, 0.09);
  width: 50%;
  padding: 34px 40px 60px;
  @media (max-width: 991px) {
    width: 100%;
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  color: #072c50;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
`;

const Input = styled.input`
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  padding: 12px 16px;
  font-size: 16px;
  color: #667085;
`;

const PhoneInput = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
`;

const CountryCode = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 12px 12px 16px;
  color: #101828;
  font-weight: 400;
`;

const DropdownIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 8px;
`;

const Textarea = styled.textarea`
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  padding: 12px 16px;
  font-size: 16px;
  color: #667085;
  min-height: 120px;
  resize: vertical;
`;

const SubmitButton = styled.button`
  border-radius: 8px;
  background: linear-gradient(201deg, #ef4236 2.29%, #faa93f 108.55%);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
`;

export default ContactForm;
